import styled from 'styled-components'
import { PageSection } from '@pancakeswap/uikit'
import { useAccount } from 'wagmi'
import useTheme from 'hooks/useTheme'
import Container from 'components/Layout/Container'
import { useTranslation } from '@pancakeswap/localization'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { ChainId } from '@pancakeswap/sdk'
import Hero from './components/Hero'
import { swapSectionData, earnSectionData, cakeSectionData } from './components/SalesSection/data'
import MetricsSection from './components/MetricsSection'
import SalesSection from './components/SalesSection'
import WinSection from './components/WinSection'
import FarmsPoolsRow from './components/FarmsPoolsRow'
import Footer from './components/Footer'
import CakeDataRow from './components/CakeDataRow'
import { WedgeTopLeft, InnerWedgeWrapper, OuterWedgeWrapper, WedgeTopRight } from './components/WedgeSvgs'
import UserBanner from './components/UserBanner'
import MultipleBanner from './components/Banners/MultipleBanner'

const StyledHeroSection = styled(PageSection)`
  padding-top: 16px;
  height: 80vh;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 48px;
  }
`

const MyStyledPageSection1 = styled(PageSection)`
  // background-color: rgba(0,0,0,0.2);
  // border: 1px solid #EBE4E9;
  // border-radius: 24px;
  > div > div:nth-child(1) > div > div > h2 {
   color: ${({ theme }) => theme.colors.p300};
    font-size: 40px;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 16px !important;
  }
  > div > div:nth-child(1) > div > div > div:nth-child(2) {
    // background-color: green;
    color: ${({ theme }) => theme.colors.n500};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 36px !important;
  }
  > div h2 {
   max-width: 360px;
  }
  @media (max-width: 967px) {
    > div > div:nth-child(1) > div {
      align-items: flex-start;
    }
    > div > div:nth-child(1) > div > div:nth-child(1) {
      padding-top: 48px !important;
      margin-bottom: 24px !important;
    }
  }
  @media (max-width: 867px) {
    > div > div:nth-child(1) > div {
      // background-color: green;
      flex-direction: row !important;
      align-items: flex-start;
    }
    > div > div:nth-child(1) > div > div:nth-child(1) {
      padding-top: 48px !important;
      margin-right: 48px !important;
      margin-bottom: 24px !important;
    }
    > div > div:nth-child(1) > div > div:nth-child(2) {
      // background-color: red;
      width: 100% !important;
      height: 100% !important;
      max-width: 320px !important;
      margin-bottom: 0 !important;
    }
  }
  @media (max-width: 767px) {
    > div > div:nth-child(1) > div > div:nth-child(1) {
      margin-right: 24px !important;
    }
    > div > div:nth-child(1) > div > div:nth-child(2) {
      max-width: 300px !important;
    }
    > div > div:nth-child(1) > div > div:nth-child(2) > div {
      margin-right: -60px;
    }
  }
  @media (max-width: 667px) {
    > div > div:nth-child(1) > div > div:nth-child(2) {
      max-width: 280px !important;
    }
    > div > div:nth-child(1) > div > div:nth-child(1) h2 {
      font-size: 32px !important;
      max-width: 280px !important;
    }
  }
  @media (max-width: 567px) {
    > div > div:nth-child(1) > div {
      flex-direction: column-reverse !important;
      align-items: center !important;
    }
    > div > div:nth-child(1) > div > div:nth-child(1) {
      padding-top: 24px !important;
    }
    > div > div:nth-child(1) > div > div:nth-child(1) h2 {
      font-size: 36px !important;
      max-width: 360px !important;
      text-align: center;
      margin-left: auto !important;
      margin-right: auto !important;
    }
    > div > div:nth-child(1) > div > div > div:nth-child(2) {
      text-align: center;
      max-width: 420px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    > div > div:nth-child(1) > div > div:nth-child(2) {
      width: calc(100% + 24px) !important;
      max-width: calc(100% + 24px) !important;
      max-width: 320px !important;
      margin-left: -24px !important;
      // margin-right: 0 !important;
    }
    > div > div:nth-child(1) > div > div:nth-child(2) > div {
      margin-right: 0;
      
    }
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: 467px) {
    > div > div:nth-child(1) > div > div:nth-child(2) {
      max-width: 300px !important;
    }
    button {
      width: 100%;
      margin: 0 !important;
    }
  }
  @media (max-width: 367px) {
    > div > div:nth-child(1) > div > div:nth-child(2) {
      max-width: 240px !important;
    }
  }
  @media (max-width: 347px) {
    > div > div:nth-child(1) > div > div:nth-child(1) h2 {
      font-size: 32px !important;
    }
  }
`

const MyStyledPageSection2 = styled(PageSection)`
  // background-color: green;
  padding: 0 !important;
  > div {
   padding: 0 !important;
  }
`

const UserBannerWrapper = styled(Container)`
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding-left: 0px;
  padding-right: 0px;

  ${({ theme }) => theme.mediaQueries.lg} {
    // padding-left: 24px;
    // padding-right: 24px;
    padding-left: 0;
    padding-right: 0;
  }
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const { theme } = useTheme()
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()

  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '100%', paddingLeft: '0', paddingRight: '0' }

  const { t } = useTranslation()

  return (
    <>
      <style jsx global>
        {`
          #home-1 .page-bg {
            background: linear-gradient(139.73deg, #e6fdff 0%, #f3efff 100%);
          }
          [data-theme='dark'] #home-1 .page-bg {
            background: radial-gradient(103.12% 50% at 50% 50%, #21193a 0%, #191326 100%);
          }
          // #home-2 .page-bg {
          //   background: linear-gradient(180deg, #ffffff 22%, #d7caec 100%);
          // }
          // [data-theme='dark'] #home-2 .page-bg {
          //   background: linear-gradient(180deg, #09070c 22%, #201335 100%);
          // }
          #home-3 .page-bg {
            background: linear-gradient(180deg, #6fb6f1 0%, #eaf2f6 100%);
          }
          [data-theme='dark'] #home-3 .page-bg {
            background: linear-gradient(180deg, #0b4576 0%, #091115 100%);
          }
          #home-4 .inner-wedge svg {
            fill: #d8cbed;
          }
          [data-theme='dark'] #home-4 .inner-wedge svg {
            fill: #201335;
          }
          body {
            // background-color: red;
            > div:first-of-type {
            //  background-color: red;
             > div:first-of-type {
              // background-color: yellow;
              > div:nth-child(1) {
               border-bottom: none;
              }
              > div:nth-child(3) {
               display: none;
              }
              > div:nth-child(4) {
              //  padding-bottom: 160px;
              padding-bottom: 96px;
                > div > div > div > div {
                  padding: 0 32px;
                  //  padding-bottom: 96px;
                  // max-width: 100% !important;
                  // background-color: purple;
                  > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
                    // max-width: 420px;
                    width: 100%;
                    flex: 1;
                  }
                  > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) {
                   max-width: 400px;
                  margin-left: auto;
                  margin-right: 0;
                  //  background-color: yellow;
                  }
                }
              }
             }
            }
          }
        `}
      </style>
      
      {/* <StyledHeroSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        containerProps={{
          id: 'home-0',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        {account && chainId === ChainId.BSC && (
          <UserBannerWrapper>
            <UserBanner />
          </UserBannerWrapper>
        )}
        <MultipleBanner />
        <Hero />
      </StyledHeroSection> */}

      {/* <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={theme.colors.background}
        containerProps={{
          id: 'home-4',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <OuterWedgeWrapper>
          <InnerWedgeWrapper top>
            <WedgeTopLeft />
          </InnerWedgeWrapper>
        </OuterWedgeWrapper>
        <SalesSection {...swapSectionData(t)} />
      </PageSection> */}

      {/* <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={theme.colors.gradientCardHeader}
        index={2}
        hasCurvedDivider={false}
      >
        <OuterWedgeWrapper>
          <InnerWedgeWrapper width="150%" top>
            <WedgeTopRight />
          </InnerWedgeWrapper>
        </OuterWedgeWrapper>
        <SalesSection {...earnSectionData(t)} /> */}
        {/* TODO: until we are enable fetch multi-chain farms */}
        {/* {chainId === ChainId.BSC && <FarmsPoolsRow />} */}
      {/* </PageSection> */}

      {/* <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        containerProps={{
          id: 'home-3',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <WinSection />
      </PageSection> */}

      <MyStyledPageSection1
        innerProps={{ style: HomeSectionContainerStyles }}
        // background={theme.colors.background}
        index={2}
        hasCurvedDivider={false}
      >
        <SalesSection {...cakeSectionData(t)} />
        <CakeDataRow />
      </MyStyledPageSection1>

      <MyStyledPageSection2
        innerProps={{ style: { margin: '0', width: '100%' } }}
        containerProps={{
          id: 'home-2',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <MetricsSection />
      </MyStyledPageSection2>
      
      {/* <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background="linear-gradient(180deg, #7645D9 0%, #5121B1 100%)"
        index={2}
        hasCurvedDivider={false}
      >
        <Footer />
      </PageSection> */}
    </>
  )
}

export default Home
