import { BigNumber } from '@ethersproject/bignumber'
import { Pool } from '@pancakeswap/uikit'
import { SerializedWrappedToken } from '@pancakeswap/token-lists'
import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { bscTokens } from '@pancakeswap/tokens'
import { PoolCategory } from './types'

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {
  [VaultKey.CakeVaultV1]: {
    name: <Trans>Auto CAKE</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 380000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.CakeVault]: {
    name: <Trans>Stake CAKE</Trans>,
    description: <Trans>Stake, Earn – And more!</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 600000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.CakeFlexibleSideVault]: {
    name: <Trans>Flexible CAKE</Trans>,
    description: <Trans>Flexible staking on the side.</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.IfoPool]: {
    name: 'IFO CAKE',
    description: <Trans>Stake CAKE to participate in IFOs</Trans>,
    autoCompoundFrequency: 1,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
      secondarySrc: `/images/tokens/ifo-pool-icon.svg`,
    },
  },
} as const

export const livePools: Pool.SerializedPoolConfig<SerializedWrappedToken>[] = [
  {
    sousId: 0,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.cake,
    contractAddress: {
      97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
      56: '0xE43F5c86B68013971830E377Bd603483896a9E20',
      // 56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
      // NEED TO UPDATE THIS, OR IT CAN'T PULL PUBLICDATA?
      // TEMPORARY
      // OR MAYBE LOAD IN A DUMMY CONTRACT HERE?
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    isFinished: false,

  },

  // ------------------------------------------------------------
  // TEST POOLS (HAS SOME MONEY IN THEM TO RECOVER!)
  // ------------------------------------------------------------
  // {
  //   sousId: 201,
  //   stakingToken: bscTokens.luigi,
  //   earningToken: bscTokens.usdt,
  //   contractAddress: {
  //     56: '0x41F523ABCfb15bcD7c1Cd6c232ebBE82E3445182',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.001000',
  //   version: 3,
  // },
  // {
  //   sousId: 202,
  //   stakingToken: bscTokens.megg,
  //   earningToken: bscTokens.usdt,
  //   contractAddress: {
  //     56: '0x1A9A6303708E791B4c42C82Ba7C0E5708299a2C5',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.001000',
  //   version: 3,
  // },
  // ------------------------------------------------------------
  // NEW LIVE POOLS
  // ------------------------------------------------------------
  {
    sousId: 101,
    stakingToken: bscTokens.mochi,
    earningToken: bscTokens.mochi,
    contractAddress: {
      56: '0x6E0cD612dea9d51ceC95711d8645767153f80c17',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2000000000.00',
    version: 3,
  },
  {
    sousId: 102,
    stakingToken: bscTokens.lpMochiBnb,
    earningToken: bscTokens.mochi,
    contractAddress: {
      56: '0xbbf3AB4874D598f5c38bBc1146aCbbe80d8bc34b',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1700000000.00',
    version: 3,
  },
  {
    sousId: 103,
    stakingToken: bscTokens.lpMochiBnb,
    earningToken: bscTokens.usdt,
    contractAddress: {
      56: '0xFa2D0CD0BdEbA9231C4E553c309583e2E788b2b5',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0005',
    version: 3,
  },
  {
    sousId: 104,
    stakingToken: bscTokens.mochi,
    earningToken: bscTokens.usdt,
    contractAddress: {
      56: '0x497e8c27c1399e1eBdb21FA5Bc10e40f74edcf75',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0005',
    version: 3,
  },
  {
    sousId: 105,
    stakingToken: bscTokens.mochi,
    earningToken: bscTokens.btcb,
    contractAddress: {
      56: '0x96ac3667fa9a3bc71F258F6EbeeB0A433F556656',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.000000016',
    version: 3,
  },
  {
    sousId: 106,
    stakingToken: bscTokens.usdt,
    earningToken: bscTokens.mochi,
    contractAddress: {
      56: '0x7Ffbc9FB408D29e6cDE985BB5582220BF08f8a2F',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '500000000',
    version: 3,
  },
  {
    sousId: 107,
    stakingToken: bscTokens.btcb,
    earningToken: bscTokens.mochi,
    contractAddress: {
      56: '0x54b863c960665B561254a7E3994F9aD5c8408F3A',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '500000000',
    version: 3,
  },
  {
    sousId: 108,
    stakingToken: bscTokens.mochi,
    earningToken: bscTokens.eth,
    contractAddress: {
      56: '0xFBd0b67fbE424b7Ca8c7B31afE85552Ac91f06Bc',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0000003',
    version: 3,
  },
  {
    sousId: 109,
    stakingToken: bscTokens.mochi,
    earningToken: bscTokens.pepe,
    contractAddress: {
      56: '0x2a94C1a51d3D4d42A61314cF09d49915C66c08E0',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '100',
    version: 3,
  },
  {
    sousId: 110,
    stakingToken: bscTokens.mochi,
    earningToken: bscTokens.sol,
    contractAddress: {
      56: '0x67a8DEE61Cc06E1a86cBCC1254B3578924940FAf',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0000075',
    version: 3,
  },
  {
    sousId: 111,
    stakingToken: bscTokens.mochi,
    earningToken: bscTokens.shib,
    contractAddress: {
      56: '0x1A5238cBbf82AA51680D9f1A9E969073b226B09C',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '133',
    version: 3,
  },  
  {
    sousId: 112,
    stakingToken: bscTokens.mochi,
    earningToken: bscTokens.floki,
    contractAddress: {
      56: '0x6f4fDB405F2b17645AA6dB005AE99c663FC0Cfce',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '9',
    version: 3,
  },
  {
    sousId: 113,
    stakingToken: bscTokens.mochi,
    earningToken: bscTokens.wbnb,
    contractAddress: {
      56: '0xB880ba7b32512f31e96b52b86c2d9b41031f3be1',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.000000975',
    version: 3,
  },
  // Pepe test pool 0x002a00D35d653D938ebC43Dd8b4DefEc4411618b
  // Pepe live pool 0x2a94C1a51d3D4d42A61314cF09d49915C66c08E0
  // ============================================
  // {
  //   sousId: 102,
  //   stakingToken: bscTokens.megg,
  //   earningToken: bscTokens.megg,
  //   contractAddress: {
  //     56: '0x4e5317d43624aefdABdf425c671826b1571DdD5A',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '350000000.00',
  //   version: 3,
  // },
  // {
  //   sousId: 103,
  //   stakingToken: bscTokens.lpLuigiBnb,
  //   earningToken: bscTokens.luigi,
  //   contractAddress: {
  //     56: '0xfC023Cb26e1015283658f12C1E2779aa22EC2ea5',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '180000000.00',
  //   version: 3,
  // },
  // {
  //   sousId: 104,
  //   stakingToken: bscTokens.lpMeggBnb,
  //   earningToken: bscTokens.megg,
  //   contractAddress: {
  //     56: '0x9EeaCDfE65350AB369BfC40754e7CF247334361f',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '260000000.00',
  //   version: 3,
  // },
  // {
  //   sousId: 105,
  //   stakingToken: bscTokens.usdt,
  //   earningToken: bscTokens.luigi,
  //   contractAddress: {
  //     56: '0x037a8Fc1dDB4dBB5175c8c961960b0f27B2e583f',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '50000000.00',
  //   version: 3,
  // },
  // {
  //   sousId: 106,
  //   stakingToken: bscTokens.usdt,
  //   earningToken: bscTokens.megg,
  //   contractAddress: {
  //     56: '0x188D296eB022a4DE22ae8c2dd2966c3eb67581fa',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '70000000.00',
  //   version: 3,
  // },
  // {
  //   sousId: 107,
  //   stakingToken: bscTokens.btcb,
  //   earningToken: bscTokens.luigi,
  //   contractAddress: {
  //     56: '0xCa1FF3e1438cD964EDE6822CAb2aC7b2DBB2F7F4',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '50000000.00',
  //   version: 3,
  // },
  // {
  //   sousId: 108,
  //   stakingToken: bscTokens.btcb,
  //   earningToken: bscTokens.megg,
  //   contractAddress: {
  //     56: '0x6795367b1f18cBccA445DB7e8aCDD75a0902309C',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '70000000.00',
  //   version: 3,
  // },
  // ------------------------------------------------------------
  // DEV TESTING POOLS
  // ------------------------------------------------------------
  // {
  //   sousId: 408,
  //   stakingToken: bscTokens.cake,
  //   earningToken: bscTokens.usdt,
  //   contractAddress: {
  //     56: '0x34Dd35daAc4825fFcadc649CA84ADe9793fB7519',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.001',
  //   version: 3,
  // },
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

// known finished pools
const finishedPools = [
  //  {
  //   sousId: 1,
  //   stakingToken: bscTokens.luigi,
  //   earningToken: bscTokens.luigi,
  //   contractAddress: {
  //     56: '0x13Aa5370b9399ffdce23b012AaE0Dcb4AEF962CB',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '300000000.00',
  //   version: 3,
  // },
  // {
  //   sousId: 2,
  //   stakingToken: bscTokens.megg,
  //   earningToken: bscTokens.megg,
  //   contractAddress: {
  //     56: '0x0A26855b5d31b22989eF09C58eA69BAA51D1C781',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '300000000.00',
  //   version: 3,
  // },
  // {
  //   sousId: 3,
  //   stakingToken: bscTokens.lpLuigiBnb,
  //   earningToken: bscTokens.luigi,
  //   contractAddress: {
  //     56: '0xEC15DB96c9DCb4dF896932103BE21a2617d6F2c9',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '500000000.00',
  //   version: 3,
  // },
  // {
  //   sousId: 4,
  //   stakingToken: bscTokens.lpMeggBnb,
  //   earningToken: bscTokens.megg,
  //   contractAddress: {
  //     56: '0x23b95037a2d9A30eCd45Fb4DDA2c7442B68ca7Ea',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '500000000.00',
  //   version: 3,
  // },
  // {
  //   sousId: 5,
  //   stakingToken: bscTokens.usdt,
  //   earningToken: bscTokens.luigi,
  //   contractAddress: {
  //     56: '0x06e28f3D93a0Fa965C40871A0bd4e9F13c07155A',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '150000000.00',
  //   version: 3,
  // },
  // {
  //   sousId: 6,
  //   stakingToken: bscTokens.usdt,
  //   earningToken: bscTokens.megg,
  //   contractAddress: {
  //     56: '0xC9131b90Cf63c81a6EA2F520476764172944EB64',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '150000000.00',
  //   version: 3,
  // },
  // {
  //   sousId: 7,
  //   stakingToken: bscTokens.btcb,
  //   earningToken: bscTokens.luigi,
  //   contractAddress: {
  //     56: '0x7380210592699423a3D1871ae77b00a3EB3C0436',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '150000000.00',
  //   version: 3,
  // },
  // {
  //   sousId: 8,
  //   stakingToken: bscTokens.btcb,
  //   earningToken: bscTokens.megg,
  //   contractAddress: {
  //     56: '0xf24151BB2708fDD8920634E083DaC33B86662306',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '150000000.00',
  //   version: 3,
  // },
].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export default [...livePools, ...finishedPools] as Pool.SerializedPoolConfig<SerializedWrappedToken>[]
