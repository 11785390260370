import { useEffect, useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Menu as UikitMenu, NextLinkFromReactRouter, footerLinks } from '@pancakeswap/uikit'
import { useTranslation, languageList } from '@pancakeswap/localization'
import PhishingWarningBanner from 'components/PhishingWarningBanner'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import useTheme from 'hooks/useTheme'
import { bscTokens } from '@pancakeswap/tokens'
import mochiAbi from 'config/abi/mochi.json'
import { useIntersectionObserver } from '@pancakeswap/hooks'
import useSWR from 'swr'
import { formatBigNumber, formatBigNumberMochi, formatLocalisedCompactNumber } from '@pancakeswap/utils/formatBalance'
import { useLuigiBNBPrice, useMochiBNBPrice, usePepeBNBPrice, useSolanaBNBPrice, useMeggBNBPrice, useCakeBusdPrice, useBNBBusdPrice } from 'hooks/useBUSDPrice'
import { usePhishingBannerManager } from 'state/user/hooks'
import { multicallv3 } from 'utils/multicall'
import { SLOW_INTERVAL } from 'config/constants'
import UserMenu from './UserMenu'
import { useMenuItems } from './hooks/useMenuItems'
import GlobalSettings from './GlobalSettings'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { SettingsMode } from './GlobalSettings/types'

// imported useswr and loaddata to this one

const abbreviateNumber = (number) => {
  const SI_SYMBOL = ['', 'K', 'M', 'B', 'T', 'Q'];

  // Return the number itself if it's less than 1
  if (Math.abs(number) < 1) {
    return Number(number.toFixed(6));
  }

  // what tier? (determines SI symbol)
  // const tier = Math.log10(Math.abs(number)) / 3 | 0;
  const tier = Math.floor(Math.log10(Math.abs(number)) / 3);

  // if zero, we don't need a suffix
  // if (tier === 0) return number.toFixed(2);

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  // const scale = Math.pow(10, tier * 3);
  const scale = 10 ** (tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  // return scaled.toFixed(2) + ' ' + suffix;
  return `$${scaled.toFixed(1)}${suffix}`;
};

const formatCurrency = (amount: number, currencyCode = 'USD') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

const Menu = (props) => {
  const { isDark, setTheme } = useTheme()

  const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true })
  // console.log('NNN1: cakePriceUsd ' + Number(cakePriceUsd))
  
  // Custom Luigi Price
  const bnbPriceUsd = useBNBBusdPrice({ forceMainnet: true })
  // console.log('NNN1: bnbPriceUsd ' + Number(bnbPriceUsd))
  const luigiPriceBnb = useLuigiBNBPrice({ forceMainnet: true })
  const mochiPriceBnb = useMochiBNBPrice({ forceMainnet: true })
  const pepePriceBnb = usePepeBNBPrice({ forceMainnet: true })
  const solanaPriceBnb = useSolanaBNBPrice({ forceMainnet: true })
  const meggPriceBnb = useMeggBNBPrice({ forceMainnet: true })


  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useRouter()
  const [showPhishingWarningBanner] = usePhishingBannerManager()

  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })


  const { observerRef, isIntersecting } = useIntersectionObserver()
  const [loadData, setLoadData] = useState(false)
  const {
    data: { cakeSupply, burnedBalance, circulatingSupply } = {
      cakeSupply: 0,
      burnedBalance: 0,
      circulatingSupply: 0,
    },
  } = useSWR(
    loadData ? ['Menu'] : null,
    async () => {
      const totalSupplyCall = {
        abi: mochiAbi,
        address: bscTokens.mochi.address,
        name: 'totalSupply'
      }
      const burnedTokenCall = {
        abi: mochiAbi,
        address: bscTokens.mochi.address,
        name: 'balanceOf',
        params: ['0x000000000000000000000000000000000000dEaD'],
      }

      const [[totalSupply], [burned]] = await multicallv3({
        calls: [totalSupplyCall, burnedTokenCall],
        allowFailure: true,
      })
      // const totalBurned = planetFinanceBurnedTokensWei.add(burned)
      const totalBurned = burned;
      const circulating = totalSupply.sub(totalBurned)

      return {
        cakeSupply: totalSupply ? +formatBigNumberMochi(totalSupply) : 0,
        burnedBalance: burned ? +formatBigNumberMochi(totalBurned) : 0,
        circulatingSupply: circulating ? +formatBigNumberMochi(circulating) : 0,
      }
    },
    {
      refreshInterval: SLOW_INTERVAL,
    },
  )

  const formattedPriceMochi =
    mochiPriceBnb && bnbPriceUsd
      ? (
        Number(mochiPriceBnb.toFixed(20)) * Number(bnbPriceUsd.toFixed(20))
      )
      .toFixed(15)
      : undefined;

  const mochiMarketcap = (circulatingSupply * Number((Number(formattedPriceMochi) * 1.002))).toFixed(2);
  const mochiMarketcapFormatted = abbreviateNumber(Number(mochiMarketcap));

  // console.log('aaxb8 mochiMarketcapFormatted: ',mochiMarketcapFormatted);
  // console.log('aaxb8 mochiMarketcapFormatted: ',typeof mochiMarketcapFormatted);



  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? 'light' : 'dark')
  }, [setTheme, isDark])

  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])

  useEffect(() => {
    if (isIntersecting) {
      setLoadData(true)
    }
  }, [isIntersecting])

  return (
    <>
    <span ref={observerRef} />
      <UikitMenu
        linkComponent={(linkProps) => {
          return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
        }}
        rightSide={
          <>
            {/* <GlobalSettings mode={SettingsMode.GLOBAL} /> */}
            <NetworkSwitcher />
            <UserMenu />
          </>
        }
        banner={showPhishingWarningBanner && typeof window !== 'undefined' && <PhishingWarningBanner />}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        cakePriceUsd={cakePriceUsd}
        bnbPriceUsd={bnbPriceUsd}
        luigiPriceBnb={luigiPriceBnb}
        mochiPriceBnb={mochiPriceBnb}
        pepePriceBnb={pepePriceBnb}
        solanaPriceBnb={solanaPriceBnb}
        meggPriceBnb={meggPriceBnb}
        mochiMarketCap={mochiMarketcapFormatted}
        links={menuItems}
        subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        buyCakeLabel={t('Buy CAKE')}
        buyCakeLink="https://app.mochidefi.io/swap?outputCurrency=0x92072F045D0904e9a0cDfD48519f54c83Bf41e82&chain=bsc"
        {...props}
      />
    </>
  )
}

export default Menu
