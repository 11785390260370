import {
  ChainId,
  Currency,
  CurrencyAmount,
  JSBI,
  Pair,
  Price,
  Token,
  WNATIVE,
  WBNB,
  ERC20Token,
  WETH9,
} from '@pancakeswap/sdk'
import { FAST_INTERVAL } from 'config/constants'
import { MOCHI, PEPE, SOL, LUIGI, MEGG, BUSD, CAKE, USDC, BTCB } from '@pancakeswap/tokens'
import { useMemo } from 'react'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import getLpAddress from 'utils/getLpAddress'
import { multiplyPriceByAmount } from 'utils/prices'
import { isChainTestnet } from 'utils/wagmi'
import { useProvider } from 'wagmi'
import { formatBigNumber, formatBigNumberMochi, formatLocalisedCompactNumber } from '@pancakeswap/utils/formatBalance'
import { usePairContract } from './useContract'
import { PairState, usePairs } from './usePairs'
import { useActiveChainId } from './useActiveChainId'

/**
 * Returns the price in BUSD of the input currency
 * @param currency currency to compute the BUSD price of
 */


export default function useBUSDPrice(currency?: Currency): Price<Currency, Currency> | undefined {
  const { chainId } = useActiveChainId()
  const wrapped = currency?.wrapped
  const wnative = WNATIVE[chainId]
  const stable = BUSD[chainId] || USDC[chainId]

  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
    () => [
      [chainId && wrapped && wnative?.equals(wrapped) ? undefined : currency, chainId ? wnative : undefined],
      [stable && wrapped?.equals(stable) ? undefined : wrapped, stable],
      [chainId ? wnative : undefined, stable],
    ],
    [wnative, stable, chainId, currency, wrapped],
  )
  const [[bnbPairState, bnbPair], [busdPairState, busdPair], [busdBnbPairState, busdBnbPair]] = usePairs(tokenPairs)

  return useMemo(() => {
    if (!currency || !wrapped || !chainId || !wnative) {
      return undefined
    }

    // handle busd
    if (wrapped.equals(stable)) {
      return new Price(stable, stable, '1', '1')
    }

    const isBUSDPairExist =
      busdPair &&
      busdPairState === PairState.EXISTS &&
      busdPair.reserve0.greaterThan('0') &&
      busdPair.reserve1.greaterThan('0')

    // handle wbnb/bnb
    if (wrapped.equals(wnative)) {
      if (isBUSDPairExist) {
        const price = busdPair.priceOf(wnative)
        return new Price(currency, stable, price.denominator, price.numerator)
      }
      return undefined
    }

    const isBnbPairExist =
      bnbPair &&
      bnbPairState === PairState.EXISTS &&
      bnbPair.reserve0.greaterThan('0') &&
      bnbPair.reserve1.greaterThan('0')
    const isBusdBnbPairExist =
      busdBnbPair &&
      busdBnbPairState === PairState.EXISTS &&
      busdBnbPair.reserve0.greaterThan('0') &&
      busdBnbPair.reserve1.greaterThan('0')

    const bnbPairBNBAmount = isBnbPairExist && bnbPair?.reserveOf(wnative)
    const bnbPairBNBBUSDValue: JSBI =
      bnbPairBNBAmount && isBUSDPairExist && isBusdBnbPairExist
        ? busdBnbPair.priceOf(wnative).quote(bnbPairBNBAmount).quotient
        : JSBI.BigInt(0)

    // all other tokens
    // first try the busd pair
    if (isBUSDPairExist && busdPair.reserveOf(stable).greaterThan(bnbPairBNBBUSDValue)) {
      const price = busdPair.priceOf(wrapped)
      return new Price(currency, stable, price.denominator, price.numerator)
    }
    if (isBnbPairExist && isBusdBnbPairExist) {
      if (busdBnbPair.reserveOf(stable).greaterThan('0') && bnbPair.reserveOf(wnative).greaterThan('0')) {
        const bnbBusdPrice = busdBnbPair.priceOf(stable)
        const currencyBnbPrice = bnbPair.priceOf(wnative)
        const busdPrice = bnbBusdPrice.multiply(currencyBnbPrice).invert()
        return new Price(currency, stable, busdPrice.denominator, busdPrice.numerator)
      }
    }

    return undefined
  }, [
    currency,
    wrapped,
    chainId,
    wnative,
    stable,
    bnbPair,
    busdBnbPair,
    busdPairState,
    busdPair,
    bnbPairState,
    busdBnbPairState,
  ])
}

export const usePriceByPairs = (currencyA?: Currency, currencyB?: Currency) => {
  const [tokenA, tokenB] = [currencyA?.wrapped, currencyB?.wrapped]
  const pairAddress = getLpAddress(tokenA, tokenB)
  const pairContract = usePairContract(pairAddress)
  const provider = useProvider({ chainId: currencyA.chainId })

  const { data: price } = useSWR(
    currencyA && currencyB && ['pair-price', currencyA, currencyB],
    async () => {
      const reserves = await pairContract.connect(provider).getReserves()
      if (!reserves) {
        return null
      }
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

      const pair = new Pair(
        CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
        CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
      )

      return pair.priceOf(tokenB)
    },
    { dedupingInterval: FAST_INTERVAL, refreshInterval: FAST_INTERVAL },
  )

  return price
}


// export const useGetBnbPriceUsd = () => {
//   console.log(`[a00xx1ab] useGetBnbPriceUsd starting...`);
//   console.log(`[a00xx1ab] useGetBnbPriceUsd ----- BNB1`);
//   const busdToken: Token = BUSD[ChainId.BSC];
//   const wbnbToken: Token = WBNB[ChainId.BSC];

//   console.log(`[a00xx1ab] useGetBnbPriceUsd ----- BNB2`);

//   const bnbCurrencyA: Currency = busdToken;
//   const bnbCurrencyB: Currency = wbnbToken;

//   console.log(`[a00xx1ab] useGetBnbPriceUsd ----- BNB3`);

//   console.log(`[a00xx1ab] useGetBnbPriceUsd ----- BNB3a`);
//   const [bnbTokenA, bnbTokenB] = [bnbCurrencyA?.wrapped, bnbCurrencyB?.wrapped]

//   console.log(`[a00xx1ab] useGetBnbPriceUsd ----- BNB3b`);
//   const bnbPairAddress = getLpAddress(bnbTokenA, bnbTokenB)

//   console.log(`[a00xx1ab] useGetBnbPriceUsd ----- BNB3c`);
//   const bnbPairContract = usePairContract(bnbPairAddress);
//   console.log(`[a000xx1ab]\nbnbPairContract:\n${bnbPairContract}`);

//   console.log(`[a00xx1ab] useGetBnbPriceUsd ----- BNB3d`);
//   const bnbProvider = useProvider({ chainId: 56 })

//   console.log(`[a00xx1ab] useGetBnbPriceUsd ----- BNB4`);

//   const { data: bnbPrice } = useSWR(
//     bnbCurrencyA && bnbCurrencyB && ['bnb-price', bnbCurrencyA, bnbCurrencyB],
//     async () => {
//       console.log(`[a00xyz] useGetBnbPriceUsd ----- 4a`);
//       const reserves = await bnbPairContract.connect(bnbProvider).getReserves();
//       console.log(`[a00xyz] useGetBnbPriceUsd ----- 4b`);
//       if (!reserves) {
//         return null
//       }
//       console.log(`[a00xyz] useGetBnbPriceUsd ----- 4c`);
//       const { reserve0, reserve1 } = reserves
//       const [token0, token1] = bnbTokenA.sortsBefore(bnbTokenB) ? [bnbTokenA, bnbTokenB] : [bnbTokenB, bnbTokenA]

//       const bnbPair = new Pair(
//         CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
//         CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
//       )

//       return bnbPair.priceOf(bnbTokenB)
//     },
//     { dedupingInterval: FAST_INTERVAL, refreshInterval: FAST_INTERVAL },
//   )

//   const formattedPriceBnb =
//     bnbPrice
//       ? (
//         Number(bnbPrice.toFixed(20))
//       )
//       .toFixed(15)
//       : undefined;

//   return formattedPriceBnb;

// }

// export const useGetMochiPriceUsd = () => {
//   const mochiToken: Token = MOCHI[ChainId.BSC];
//   const wbnbToken: Token = WBNB[ChainId.BSC];

//   const mochiCurrencyA: Currency = wbnbToken;
//   const mochiCurrencyB: Currency = mochiToken;

//   const [mochiTokenA, mochiTokenB] = [mochiCurrencyA?.wrapped, mochiCurrencyB?.wrapped]
//   const mochiPairAddress = getLpAddress(mochiTokenA, mochiTokenB)
//   const mochiPairContract = usePairContract(mochiPairAddress)
//   const mochiProvider = useProvider({ chainId: 56 })

//   const bnbPriceUsd = Number(useGetBnbPriceUsd())
//   // const bnbPriceUsd = Number(1)

//   const { data: mochiPrice } = useSWR(
//     mochiCurrencyA && mochiCurrencyB && ['mochi-price', mochiCurrencyA, mochiCurrencyB],
//     async () => {
//       const reserves = await mochiPairContract.connect(mochiProvider).getReserves();
//       if (!reserves) {
//         return null
//       }
//       const { reserve0, reserve1 } = reserves
//       const [token0, token1] = mochiTokenA.sortsBefore(mochiTokenB) ? [mochiTokenA, mochiTokenB] : [mochiTokenB, mochiTokenA]

//       const mochiPair = new Pair(
//         CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
//         CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
//       )

//       const priceOfMochiPair = mochiPair.priceOf(mochiTokenB);

//       const priceOfMochiPairFixed = Number(priceOfMochiPair.toFixed(20));

//       return mochiPair.priceOf(mochiTokenB)
//     },
//     { dedupingInterval: FAST_INTERVAL, refreshInterval: FAST_INTERVAL },
//   )

//   const formattedPriceMochi =
//     mochiPrice
//       ? (
//         Number(mochiPrice.toFixed(20)) * Number(bnbPriceUsd.toFixed(20))
//       )
//       .toFixed(15)
//       : undefined;

//   return formattedPriceMochi;

// }


export const useLpTokenPrice = (currencyA?: Currency, currencyB?: Currency, currencyPriceA?: number, currencyPriceB?: number) => {  
  const [tokenA, tokenB] = [currencyA?.wrapped, currencyB?.wrapped]
  const pairAddress = getLpAddress(tokenA, tokenB)
  const pairContract = usePairContract(pairAddress)
  const provider = useProvider({ chainId: currencyA.chainId })

  const bnbPriceUsd = currencyPriceA;
  const mochiPriceUsd = currencyPriceB;

  const { data: lpPrice } = useSWR(
    currencyPriceA && currencyPriceB && ['lp-price', currencyA, currencyB],
    async () => {

      const bnbPriceUsdX = currencyPriceA;
      const mochiPriceUsdX = currencyPriceB;
      const reserves = await pairContract.connect(provider).getReserves();

      if (!reserves) {
        return null
      }

      const { reserve0, reserve1 } = reserves
      const reserve0Format = Number(formatBigNumberMochi(reserve0)) * Number(1);
      const reserve1Format = Number(formatBigNumber(reserve1)) * Number(1);
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      const reserve0Value = Number(reserve0Format) * Number(mochiPriceUsdX);
      const reserve1Value = Number(reserve1Format) * Number(bnbPriceUsdX);
      const lpTotalValue = (reserve0Value + reserve1Value);
      const lpTotalSupply = await pairContract.connect(provider).totalSupply()
      if (!lpTotalSupply) { return null }
      const lpTotalSupplyFormatted = Number(formatBigNumber(lpTotalSupply));
      const theLpPrice = (Number(lpTotalValue) / Number(lpTotalSupplyFormatted));

      return theLpPrice;

    },
    { dedupingInterval: FAST_INTERVAL, refreshInterval: FAST_INTERVAL },
  )

  return lpPrice
}



export const useBUSDCurrencyAmount = (currency?: Currency, amount?: number): number | undefined => {
  const busdPrice = useBUSDPrice(currency?.chainId === ChainId.ETHEREUM ? undefined : currency)
  // we don't have too many AMM pools on ethereum yet, try to get it from api
  const { data } = useSWRImmutable(
    amount && currency?.chainId === ChainId.ETHEREUM && ['fiat-price-ethereum', currency],
    async () => {
      const address = currency.isToken ? currency.address : WETH9[ChainId.ETHEREUM].address
      return fetch(`https://coins.llama.fi/prices/current/ethereum:${address}`) // <3 llama
        .then((res) => res.json())
        .then(
          (res) => res?.coins?.[`ethereum:${address}`]?.confidence > 0.9 && res?.coins?.[`ethereum:${address}`]?.price,
        )
    },
    {
      dedupingInterval: 30_000,
      refreshInterval: 30_000,
    },
  )

  if (amount) {
    if (data) {
      return parseFloat(data) * amount
    }
    if (busdPrice) {
      return multiplyPriceByAmount(busdPrice, amount)
    }
  }
  return undefined
}

export const useBUSDCakeAmount = (amount: number): number | undefined => {
  const cakeBusdPrice = useCakeBusdPrice()
  if (cakeBusdPrice) {
    return multiplyPriceByAmount(cakeBusdPrice, amount)
  }
  return undefined
}

// @Note: only fetch from one pair
export const useCakeBusdPrice = (
  { forceMainnet } = { forceMainnet: false },
): Price<ERC20Token, ERC20Token> | undefined => {
  const { chainId } = useActiveChainId()
  const isTestnet = !forceMainnet && isChainTestnet(chainId)
  // Return bsc testnet cake if chain is testnet
  const cake: Token = isTestnet ? CAKE[ChainId.BSC_TESTNET] : CAKE[ChainId.BSC]
  return usePriceByPairs(BUSD[cake.chainId], cake)
}

// @Note: only fetch from one pair
export const useBNBBusdPrice = (
  { forceMainnet } = { forceMainnet: false },
): Price<ERC20Token, ERC20Token> | undefined => {
  const { chainId } = useActiveChainId()
  const isTestnet = !forceMainnet && isChainTestnet(chainId)
  // Return bsc testnet wbnb if chain is testnet
  const wbnb: Token = isTestnet ? WBNB[ChainId.BSC_TESTNET] : WBNB[ChainId.BSC]
  return usePriceByPairs(BUSD[wbnb.chainId], wbnb)
}

// @Note: only fetch from one pair
export const useLuigiBNBPrice = (
  { forceMainnet } = { forceMainnet: false },
): Price<ERC20Token, ERC20Token> | undefined => {
  const luigi: Token = LUIGI[ChainId.BSC]
  const wbnb: Token = WBNB[ChainId.BSC]
  return usePriceByPairs(wbnb, luigi)
}

// @Note: only fetch from one pair
export const useMochiBNBPrice = (
  { forceMainnet } = { forceMainnet: false },
): Price<ERC20Token, ERC20Token> | undefined => {
  const mochi: Token = MOCHI[ChainId.BSC]
  const wbnb: Token = WBNB[ChainId.BSC]
  return usePriceByPairs(wbnb, mochi)
}

// @Note: only fetch from one pair
export const useLpMochiBnbPrice = (aPrice?: number, bPrice?: number): any => {
  const mochi: Token = MOCHI['56']
  const wbnb: Token = WBNB['56']
  const wbnbPrice = Number(aPrice);
  const mochiPrice = Number(bPrice);
  return useLpTokenPrice(wbnb, mochi, wbnbPrice, mochiPrice);
}

// @Note: only fetch from one pair
export const usePepeBNBPrice = (
  { forceMainnet } = { forceMainnet: false },
): Price<ERC20Token, ERC20Token> | undefined => {
  const pepe: Token = PEPE[ChainId.BSC]
  const wbnb: Token = WBNB[ChainId.BSC]
  return usePriceByPairs(wbnb, pepe)
}

// @Note: only fetch from one pair
export const useBtcbBNBPrice = (
  { forceMainnet } = { forceMainnet: false },
): Price<ERC20Token, ERC20Token> | undefined => {
  const btcb: Token = BTCB[ChainId.BSC]
  const wbnb: Token = WBNB[ChainId.BSC]
  return usePriceByPairs(wbnb, btcb)
}


// @Note: only fetch from one pair
export const useSolanaBNBPrice = (
  { forceMainnet } = { forceMainnet: false },
): Price<ERC20Token, ERC20Token> | undefined => {
  const sol: Token = SOL[ChainId.BSC]
  const wbnb: Token = WBNB[ChainId.BSC]
  return usePriceByPairs(wbnb, sol)
}


// @Note: only fetch from one pair
export const useMeggBNBPrice = (
  { forceMainnet } = { forceMainnet: false },
): Price<ERC20Token, ERC20Token> | undefined => {
  const megg: Token = MEGG[ChainId.BSC]
  const wbnb: Token = WBNB[ChainId.BSC]
  return usePriceByPairs(wbnb, megg)
}
