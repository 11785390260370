import styled from 'styled-components'
import Link from 'next/link';
import { Heading, Flex, Text, Skeleton, ChartIcon, CommunityIcon, SwapIcon, moIconSwapArrows2 as MoIconSwapArrows2, moIconLayers as MoIconLayers } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import { formatLocalisedCompactNumber } from '@pancakeswap/utils/formatBalance'
import useSWRImmutable from 'swr/immutable'
import IconCard, { IconCardData } from '../IconCard'
import StatCardContent from './StatCardContent'

const MyStyledFlex = styled(Flex)`
  //  background-color: red;
   max-width: 100%;
   width: calc(100% + 32px);
   max-width: calc(100% + 32px);
   justify-content: center;
  //  gap: 24px;
  margin-left: -16px;
  margin-right: -16px;
   > a {
    width: calc(33.333333% - 32px);
    box-sizing: border-box;
    margin: 0 16px;
  }
  > a > div {
    margin: 0 !important; 
  }
  > a > div > div {
    transition-duration: 0.25s;
  }
  > a > div div:nth-child(2) > div:nth-child(3) {
    max-width: 100%;
  }
  @media (min-width: 868px) {
    > a:hover > div > div {
      transform: translateY(-8px);
      box-shadow: 5px 46px 53px -2px #a9538163;
    }
  }
  @media (max-width: 1299px) {
    width: calc(100% + 16px);
    max-width: calc(100% + 16px);
    margin-left: -8px;
    margin-right: -8px;
    > a {
      width: calc(33.333333% - 16px);
      margin: 0 8px !important;
    }
    > a > div div:nth-child(2) > div:nth-child(3) {
      max-width: 160px;
    }
  }
  @media (min-width: 600px) and (max-width: 867px) {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    > a:nth-child(1),
    > a:nth-child(2) {
     margin: 0 !important;
      width: calc(50% - 8px);
    }
    > a:nth-child(3) {
     margin: 0 !important;
     margin-top: 16px !important;
     width: 100%;
    }
    > a > div > div > div {
      text-align: center;
    }
    > a > div > div > div > div:nth-child(1) {
      // background-color: yellow;
      position: relative;
      top: initial !important;
      left: initial !important;
      right: initial !important;
      bottom: initial !important;
      text-align: center;
      margin-bottom: 16px;
    }
    > a > div > div > div > div:nth-child(2) > div:nth-child(3) {
      text-align: center;
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100% !important;
      width: 100% !important;
    }
  }
  @media (max-width: 599px) {
    width: 100%;
    max-width: 100%;
    > a {
      // background-color: red;
      width: 100% !important;
      max-width: 100% !important;
      margin: 0 !important;
      margin-bottom: 8px !important;
    }
  }

`

const MyStyledHeading = styled(Heading)`
  padding-top: 48px;
  margin-bottom: 24px;
`

const MyStyledIconCard1 = styled(IconCard)`
  width: 100%;
  // > div {box-shadow: 5px 46px 53px -2px rgba(255,33,152,0.15) !important;}
  // > div > div {
  //   box-shadow: none !important;
  // }
`

const MyStyledIconCard2 = styled(IconCard)`
  width: 100%;
  // > div {box-shadow: 5px 46px 53px -2px rgba(255,33,152,0.15) !important;}
  // > div > div {
  //   box-shadow: none !important;
  // }
  svg path {
    stroke: ${({ theme }) => theme.colors.primary};
    fill: rgba(0,0,0,0);
  }
`


const MyStyledIconCard3 = styled(IconCard)`
// background-color: yellow;
  width: 100%;
  // div {box-shadow: 5px 46px 53px -2px rgba(255,33,152,0.15) !important;}
  // > div > div {
  //   box-shadow: none !important;
  // }
  > div {
    // background: ${({ theme }) => theme.colors.primary} !important;
    // background-color: ${({ theme }) => theme.colors.primary} !important;
    background: linear-gradient(45deg, ${({ theme }) => theme.colors.p600} 0%, ${({ theme }) => theme.colors.p900} 100%);
    // border: 1px solid #FFFFFF;
    border: 1px solid #ff008f;
  }
  > div h2 {
    color: ${({ theme }) => theme.colors.white};
    // color: rgb(254,243,251);
  }
  > div h2:nth-child(2) {
    color: ${({ theme }) => theme.colors.p1300};
  }
  > div div:nth-child(3) {
    color: ${({ theme }) => theme.colors.white};
  }
  > div svg {
    fill: ${({ theme }) => theme.colors.p1300} !important;
  }
`

const Stats = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  const { data: tvl } = useSWRImmutable('tvl')
  const { data: txCount } = useSWRImmutable('totalTx30Days')
  const { data: addressCount } = useSWRImmutable('addressCount30Days')
  const trades = formatLocalisedCompactNumber(txCount)
  const users = formatLocalisedCompactNumber(addressCount)
  const tvlString = tvl ? formatLocalisedCompactNumber(tvl) : '-'

  const tvlText = t('And those users are now entrusting the platform with over $%tvl% in funds.', { tvl: tvlString })
  const [entrusting, inFunds] = tvlText.split(tvlString)

  const UsersCardData: IconCardData = {
    icon: <MoIconSwapArrows2 color="secondary" width="36px" />,
  }

  const TradesCardData: IconCardData = {
    icon: <MoIconLayers color="primary" width="36px" />,
  }

  const StakedCardData: IconCardData = {
    icon: <CommunityIcon color="failure" width="36px" />,
  }

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      {/* <GradientLogo height="48px" width="48px" mb="24px" /> */}
      <MyStyledHeading textAlign="center" scale="xl">
        {t('Get started')}
      </MyStyledHeading>
      {/* <Heading textAlign="center" scale="xl" mb="32px">
        {t('Swap, Stake, Earn.')}
      </Heading> */}
      {/* <Text textAlign="center" color="textSubtle">
        {t('PancakeSwap has the most users of any decentralized platform, ever.')}
      </Text> */}
      {/* <Flex flexWrap="wrap">
        <Text display="inline" textAlign="center" color="textSubtle" mb="20px">
          {entrusting}
          <>{tvl ? <>{tvlString}</> : <Skeleton display="inline-block" height={16} width={70} mt="2px" />}</>
          {inFunds}
        </Text>
      </Flex> */}
      {/* <Text textAlign="center" color="textSubtle" bold mb="32px">
        {t('Will you join them?')}
      </Text> */}

      <MyStyledFlex maxWidth="100%" flexDirection={['column', null, null, 'row']}>
        <Link href="/swap" passHref legacyBehavior>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>
            <MyStyledIconCard1 {...UsersCardData} mr={[null, null, null, '16px']} mb={['16px', null, null, '0']}>
              <StatCardContent
                // headingText={t('%users% users', { users })}
                // bodyText={t('in the last 30 days')}
                // highlightColor={theme.colors.secondary}
                headingText={t('SWAP\ninstantly')}
                bodyText={t('Buy MOCHI, BTC, ETH, & more!')}
                highlightColor={theme.colors.secondary}
              />
            </MyStyledIconCard1>
          </a>
        </Link>
        <Link href="/pools/" passHref legacyBehavior>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>
            <MyStyledIconCard2 {...TradesCardData} mr={[null, null, null, '16px']} mb={['16px', null, null, '0']}>
              <StatCardContent
                headingText={t('STAKE\nto earn')}
                bodyText={t('Highest APR on BNB Chain!')}
                highlightColor={theme.colors.primary}
              />
            </MyStyledIconCard2>
          </a>
        </Link>
        <Link href="https://t.me/Mochi_DeFi/" passHref legacyBehavior>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>
            <MyStyledIconCard3 {...StakedCardData}>
              <StatCardContent
                headingText={t('JOIN\nthe gang')}
                bodyText={t('Based & cutemaxxed community!')}
                highlightColor={theme.colors.white}
              />
            </MyStyledIconCard3>
          </a>
        </Link>
      </MyStyledFlex>
    </Flex>
  )
}

export default Stats
