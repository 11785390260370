import { useIsMounted } from "@pancakeswap/hooks";
import { AtomBox } from "@pancakeswap/ui/components/AtomBox";
import throttle from "lodash/throttle";
import React, { useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components";
import BottomNav from "../../components/BottomNav";
import { Box } from "../../components/Box";
import Flex from "../../components/Box/Flex";
import CakePrice from "../../components/CakePrice/CakePrice";
import Footer from "../../components/Footer";
import LangSelector from "../../components/LangSelector/LangSelector";
import MenuItems from "../../components/MenuItems/MenuItems";
import { SubMenuItems } from "../../components/SubMenuItems";
import { useMatchBreakpoints } from "../../contexts";
import Logo from "./components/Logo";
import { MENU_HEIGHT, MOBILE_MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from "./config";
import { MenuContext } from "./context";
import { NavProps } from "./types";

// import { useLocation } from 'react-router-dom';
// const location = useLocation();
// const pathsToHideBorder = ['/path1', '/path2', '/path3'];
// const hideBorder = pathsToHideBorder.includes(location.pathname);

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-right: 1px solid #D9CBD5;
  height: 100%;
  // background-color: ${({ theme }) => theme.nav.background};
  background: linear-gradient(90deg, #E5C1A8 0%, #FDEAD9 50.62%, #FFF2E2 101.23%);
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-bottom: 1px solid #E8C6AE;
  border-bottom: 0;
  transform: translate3d(0, 0, 0);

  @media (max-width: 1067px) {
    flex-direction: row;
    height: 100%;
    border-right: none !important;
    border-bottom: 1px solid #D9CBD5;
    > div {
      justify-content: space-between;
      justify-content: flex-start;
      // padding-right: 136px;
      // padding-right: 143px;
      // padding-right: 62px;
      // @media (max-width: 851px) {
      //   padding-right: 98px;
      //   padding-right: 104px;
      // }
    }
  }

  @media (max-width: 467px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  padding-left: 16px;
  padding-right: 16px;
  position: initial;

  > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1),
  > div:nth-child(2) > div:nth-child(4) > div:nth-child(1) > div:nth-child(1) {
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
  }
  > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > img {
    width: 24px;
    height: 24px;
    border: none !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    border: 1px solid #ffcc1d;
    border-radius: 50%;
    background-color: #010f11;
    background-color: #0c0d12;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  > div:nth-child(2) > div:nth-child(4) > div:nth-child(1) > div:nth-child(1) > svg {
    width: 16px;
  }
  > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) > div:nth-child(1),
  > div:nth-child(2) > div:nth-child(4) > div:nth-child(1) {
    border-radius: 50%;
    // height: 40px;
    // width: 40px;
    height: 32px;
    width: 32px;
  }
  > div:nth-child(2) > div:nth-child(4) > div:nth-child(1) {
    padding-left: 40px;
  }
  > div:nth-child(2) > button:nth-child(4) {
    border-radius: 9999px;
    height: 40px;
    border: none !important;
    box-shadow: none;
    background-color: rgba(49, 203, 158, 0.3);
    background-color: #1d2b2d;
    background: var(--Nav-Btn-Gradient, linear-gradient(90deg, #DC097A 0%, #FF018D 100%));
    color: #04e081;
    color: #FFFAFD;
    font-size: 14px;
    letter-spacing: normal !important;
    padding-left: 12px;
    padding-right: 12px;
  }
  > div:nth-child(2) > div:nth-child(3) > div:nth-child(1) {
    margin-right: 8px !important;
  }
  > div:nth-child(2) > div:nth-child(2) {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
  @media (max-width: 567px) {
    > div:nth-child(2) > div:nth-child(2) {
      margin-right: 20px !important;
    }
  }
  @media (max-width: 467px) {
    > div:nth-child(2) > div:nth-child(2) {
      margin-right: 8px !important;
    }
  }
`;

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: fixed;
  // top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  top: ${({ showMenu, height }) => (showMenu ? 0 : 0)};
  left: 0;
  transition: top 0.2s;
  height: 100%;
  width: 240px;
  z-index: 20;
  @media (max-width: 1067px) {
    height: 60px;
    width: 100vw;
    position: initial;
  }
`;

const TopBannerContainer = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  min-height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};
  width: 100%;
`;

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  max-width: 100vw;
`;

const Inner = styled.div`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
  // @media (max-width: 567px) {
  //   margin-left: 0 !important;
  //   margin-right: 0 !important;
  // }
`;

const StyledFlex1 = styled(Flex)`
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  padding-top: 16px;
  @media (max-width: 1067px) {
    flex-direction: row;
    padding-top: 0;
  }
  > a:nth-child(3) {
   margin-top: auto;
   padding-left: 16px !important;
   padding-bottom: 16px !important;
  }
  @media (max-width: 1067px) {
    > a:nth-child(3) {
      padding: 0 !important;
      display: none !important;
    }
  }
`;

const StyledFlex2 = styled(Flex)`
  // background-color: purple;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 32px;
  // padding-right: 60px;
  width: 100%;
  max-width: calc(100% - 240px);
  height: 68px;
  margin-left: calc(240px);
  // margin-right: 32px;
  margin-right: 0 !important;
  box-sizing: border-box;
  // box-sizing: content-box;
  border-bottom: 1px solid #EBE4E9;
  border-bottom: 1px solid #d9cbd5;

  @media (min-width: 1068px) {
    > div:nth-child(1) {
      display: none !important;
    }
  }

  @media (max-width: 1067px) {
    width: initial;
    max-width: 100%;
    margin-left: initial;
    flex-direction: row;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    height: 60px;
    z-index: 9999;
    right: 16px;
    border-bottom: 0 !important;
  }

  // box-shadow: 20px 46px 48px -2px #00000006;

  border-bottom: 0 !important;
  background-color: rgba(0,0,0,0) !important;
  z-index: 1;
`;

const StyledMenuItems = styled(MenuItems)`
  // background-color: red;
  margin-top: 32px;
  margin-top: 16px;
  flex-direction: column;
  margin-left: 0;
  gap: 4px;
  > div {
    padding: 0 !important;
  }
  // > div:nth-child(1) a > span > svg:nth-child(1) {
  //   border: 2px solid red;
  // }
  // > div:nth-child(1) a > span > svg:nth-child(2) {
  //   border: 2px solid green;
  // }


  > div:nth-child(1) {
    > div:nth-child(2) {
      display: none !important; 
    }
  }

  > div:nth-child(3) {
   display: none !important;
  }


  @media (max-width: 1067px) {
    margin-top: 0;
    flex-direction: row;
    > div:nth-child(5) {
      display: block !important;
      margin-left: 32px;
      height: 100%;
      padding-top: 12px !important;
    }
    > div:nth-child(5) > div:nth-child(2) {
      transform: translate3d(201px, 48px, 0px);
    }
    > div:nth-child(5) a {
      height: 36px;
      background-color: #FFFFFF;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // justify-content: center;
    }
    // > div:nth-child(5) > div {
    //   height: 60px;
    // }
    // > div:nth-child(5) > div > div {
    //   height: 60px;
    // }
    // > div:nth-child(5) > div > div > a {
    //   height: 60px;
    // }
    > div:nth-child(3) {
      display: block !important;
      svg {
       display: none !important;
      }
    }
    > div:nth-child(1) {
      display: none;
    }
    > div:nth-child(2) {
      display: none;
    }
  }

`;

const StyledAtomBox = styled(AtomBox)`
  margin-right: 12px !important;
  position: relative;
  @media (max-width: 467px) {
    margin-right: 8px !important;
  }
  > div {
   display: none !important;
  }
`;

const StyledMochiFlex = styled(Flex)`
  // background-color: #fffafd !important;
  margin-left: 240px;
  padding-left: 32px;
  padding-right: 32px;
  > div:nth-child(1) {
    margin-top: 0;
    // border-top: 1px solid #EBE4E9;
    @media (max-width: 1067px) {
      border-top: none
    }
  }
  @media (max-width: 1067px) {
    margin-left: 0;
  }
  // display: none;
`;

const StyledBodyWrapper = styled(BodyWrapper)`
  margin-left: 240px;
  @media (max-width: 1067px) {
    margin-left: 0;
    align-items: center;
    justify-content: center;
  }
  > div:nth-child(1) {
    // margin-left: 60px;w
    // margin-right: 60px;
    width: 100%;
    max-width: 1180px;

    margin-left: 0;
    margin-right: 0;
    // max-width: 100%;

    // box-sizing: content-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 60px;
    padding-right: 60px;
    @media (max-width: 867px) {
      margin-left: 0;
      margin-right: 0;
      padding-left: 32px ;
      padding-right: 32px;
    }
    @media (max-width: 667px) {
      padding-left: 16px ;
      padding-right: 16px;
    }
  }
  > div:nth-child(1) > div:nth-child(1) {
    // border-top: 1px solid #EBE4E9;
  }
`;

const Menu: React.FC<React.PropsWithChildren<NavProps>> = ({
  linkComponent = "a",
  banner,
  rightSide,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  cakePriceUsd,
  bnbPriceUsd,
  luigiPriceBnb,
  mochiPriceBnb,
  pepePriceBnb,
  solanaPriceBnb,
  meggPriceBnb,
  mochiMarketCap,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyCakeLabel,
  buyCakeLink,
  children,
}) => {
  const { isMobile } = useMatchBreakpoints();
  const isMounted = useIsMounted();
  const [showMenu, setShowMenu] = useState(true);
  const refPrevOffset = useRef(typeof window === "undefined" ? 0 : window.pageYOffset);

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT;

  const totalTopMenuHeight = isMounted && banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT;


  // console.log('OOO1: cakePriceUsd: ' + Number(cakePriceUsd))

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    };
    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [totalTopMenuHeight]);

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === "Home");

  const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly);
  const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly);
  const providerValue = useMemo(() => ({ linkComponent }), [linkComponent]);
  return (
    <MenuContext.Provider value={providerValue}>
      <AtomBox
        asChild
        minHeight={{
          xs: "auto",
          md: "100vh",
        }}
      >
        <Wrapper>
          <StyledFlex2 alignItems="center" height="68px">
            <StyledAtomBox mr="12px" display={{ xs: "block", lg: "block" }}>
              <CakePrice showMegg={false} showSkeleton={false} cakePriceUsd={cakePriceUsd} bnbPriceUsd={bnbPriceUsd} luigiPriceBnb={luigiPriceBnb} mochiPriceBnb={mochiPriceBnb} mochiMarketCap={mochiMarketCap} />
            </StyledAtomBox>
            {/* <StyledAtomBox display={{ xs: "block", lg: "block" }} mr={{ xs: "12px", lg: "32px" }}>
              <CakePrice showMegg={true} showSkeleton={false} cakePriceUsd={cakePriceUsd} bnbPriceUsd={bnbPriceUsd} meggPriceBnb={meggPriceBnb} />
            </StyledAtomBox> */}
            {/* <Box mt="4px">
              <LangSelector
                currentLang={currentLang}
                langs={langs}
                setLang={setLang}
                buttonScale="xs"
                color="textSubtle"
                hideLanguage
              />
            </Box> */}
            {rightSide}
          </StyledFlex2>
          <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
            {/* {banner && isMounted && <TopBannerContainer height={topBannerHeight}>{banner}</TopBannerContainer>} */}
            <StyledNav>
              <StyledFlex1>
                {/* <Logo href={homeLink?.href ?? "/"} /> */}
                <Logo href={"/"} />
                <AtomBox display={{ xs: "block", md: "block" }}>
                  <StyledMenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} ml="24px" />
                </AtomBox>
                <CakePrice showMegg={false} showSkeleton={false} cakePriceUsd={cakePriceUsd} bnbPriceUsd={bnbPriceUsd} luigiPriceBnb={luigiPriceBnb} mochiPriceBnb={mochiPriceBnb} mochiMarketCap={mochiMarketCap} />
              </StyledFlex1>
            </StyledNav>
          </FixedContainer>
          {subLinks ? (
            <StyledMochiFlex justifyContent="space-around" overflow="hidden">
              <SubMenuItems
                items={subLinksWithoutMobile}
                mt={`${totalTopMenuHeight + 1}px`}
                activeItem={activeSubItem}
              />

              {subLinksMobileOnly && subLinksMobileOnly?.length > 0 && (
                <SubMenuItems
                  items={subLinksMobileOnly}
                  mt={`${totalTopMenuHeight + 1}px`}
                  activeItem={activeSubItem}
                  isMobileOnly
                />
              )}
            </StyledMochiFlex>
          ) : (
            <div />
          )}
          <StyledBodyWrapper mt={!subLinks ? `0` : "0"}>
            <Inner>{children}</Inner>
          </StyledBodyWrapper>
        </Wrapper>
      </AtomBox>
      {/* <Footer
        items={footerLinks}
        isDark={isDark}
        toggleTheme={toggleTheme}
        langs={langs}
        setLang={setLang}
        currentLang={currentLang}
        cakePriceUsd={cakePriceUsd}
        buyCakeLabel={buyCakeLabel}
        buyCakeLink={buyCakeLink}
        mb={[`${MOBILE_MENU_HEIGHT}px`, null, "0px"]}
      /> */}
      <AtomBox display={{ xs: "block", md: "none" }}>
        <BottomNav items={links} activeItem={activeItem} activeSubItem={activeSubItem} />
      </AtomBox>
    </MenuContext.Provider>
  );
};

export default Menu;
