import { Flex, Heading, Skeleton, Text, Balance } from '@pancakeswap/uikit'
import cakeAbi from 'config/abi/cake.json'
import mochiAbi from 'config/abi/mochi.json'
import { bscTokens } from '@pancakeswap/tokens'
import { useTranslation } from '@pancakeswap/localization'
import { useIntersectionObserver } from '@pancakeswap/hooks'
import { useEffect, useState } from 'react'
import { usePriceCakeBusd } from 'state/farms/hooks'
import styled from 'styled-components'
import { formatBigNumber, formatBigNumberMochi, formatLocalisedCompactNumber } from '@pancakeswap/utils/formatBalance'
import { multicallv3 } from 'utils/multicall'
import { getCakeVaultAddress } from 'utils/addressHelpers'
import useSWR from 'swr'
import { SLOW_INTERVAL } from 'config/constants'
import cakeVaultV2Abi from 'config/abi/cakeVaultV2.json'
import { BigNumber } from '@ethersproject/bignumber'
import { useMochiBNBPrice, useBNBBusdPrice } from 'hooks/useBUSDPrice'




// import { MenuContext } from "@pancakeswap/uikit/src/widgets/menu/context";
// import { useMochiBNBPrice, useBNBBusdPrice } from 'hooks/useBUSDPrice'
// const bnbPriceUsd = useBNBBusdPrice({ forceMainnet: true })
// const mochiPriceBnb = useMochiBNBPrice({ forceMainnet: true })

// console.log('aa9: ' + bnbPriceUsd);

const abbreviateTheNumber = (number) => {
  const SI_SYMBOL = ['', 'K', 'M', 'B', 'T', 'Q'];

  // Return the number itself if it's less than 1
  if (Math.abs(number) < 1) {
    return Number(number.toFixed(6));
  }

  // what tier? (determines SI symbol)
  // const tier = Math.log10(Math.abs(number)) / 3 | 0;
  const tier = Math.floor(Math.log10(Math.abs(number)) / 3);

  // if zero, we don't need a suffix
  // if (tier === 0) return number.toFixed(2);

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  // const scale = Math.pow(10, tier * 3);
  // const scale = Math.pow(10, tier * 3);
  const scale = 10 ** (tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  // return scaled.toFixed(2) + ' ' + suffix;
  return `${scaled.toFixed(2)}${suffix}`;
};

const formatCurrency = (amount: number, currencyCode = 'USD') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};


const StyledColumn = styled(Flex)<{ noMobileBorder?: boolean; noDesktopBorder?: boolean }>`
  flex-direction: column;
  ${({ noMobileBorder, theme }) =>
    noMobileBorder
      ? `${theme.mediaQueries.md} {
           padding: 0 16px;
           border-left: 0px ${theme.colors.inputSecondary} solid;
         }
       `
      : `border-left: 0px ${theme.colors.inputSecondary} solid;
         padding: 0 8px;
         ${theme.mediaQueries.sm} {
           padding: 0 16px;
         }
       `}

  ${({ noDesktopBorder, theme }) =>
    noDesktopBorder &&
    `${theme.mediaQueries.md} {
           padding: 0;
           border-left: none;
         }
       `}
`

const Grid = styled.div`
  display: grid;
  grid-gap: 16px 8px;
  margin-top: 60px;
  grid-template-columns: repeat(2, auto);
  grid-template-areas:
    'a d'
    'b e'
    'c f';

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-gap: 16px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-areas:
      'a b c'
      'd e f';
    grid-gap: 32px;
    grid-template-columns: repeat(3, auto);
  }
`


const MyStyledSubText = styled(Text)`
  font-size: 13px;
  line-height: 1;
  margin-top: 2px;
  opacity: 0.7;
  color: ${({ theme }) => theme.colors.n700};
  @media (max-width: 967px) {
    font-size: 12px;
    line-height: 1;
    margin-top: 2px;
  }
`

const MyStyledFlexBox = styled(Flex)`
  // background-color: rgba(0,0,0,0.1);
  padding-top: 48px;
  justify-content: space-evenly;
  // flex-wrap: wrap !important;
  flex-direction: row;
  // gap: 24px;
  > div {
    flex: initial !important;
    // min-width: 25%;
    // width: 100%;
    // max-width: 25%;
    padding: 0 !important;
  }
  @media (max-width: 799px) {
    gap: 0;
    > div {
      // background-color: green;
    }
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
    max-width: 540px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: auto;
    margin-right: auto;
    > div {
      border-left: 1px solid rgba(217,203,213,0.6);
      padding-left: 24px !important;
      width: 50%;
      margin-bottom: 48px;
    }
    > div:nth-child(5),
    > div:nth-child(7) {
      margin-bottom: 0 !important;
    }
  }
  @media (max-width: 567px) {
    max-width: 480px;
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: 467px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 48px;
    // flex-direction: column;
    // align-items: center;
    > div {
      border: 0 !important;
      padding-left: 0 !important;
      text-align: center;
      margin-bottom: 48px !important;
    }
    > div:nth-child(5) {
      margin-bottom: 0 !important;
    }
    > div:nth-child(5) {
      margin-bottom: 0 !important;
    }
    > div h2 {
      font-size: 32px;
    }
  }
  @media (max-width: 427px) {
    > div h2 {
      font-size: 28px;
    }
  }
  @media (max-width: 387px) {
    flex-direction: column;
    align-items: center;
    > div h2 {
      font-size: 36px;
    }
    > div:nth-child(5) {
      margin-bottom: 48px !important;
    }
  }
`

const MyStyledDivLine = styled.div`
  background-color: #d9cbd5;
  width: 1px;
  opacity: 0.7;
  @media (max-width: 867px) {
    opacity: 0.4;
  }
  @media (max-width: 767px) {
    display: none !important;
    // max-width: 1px !important;
  }
`

const MyStyledSkeleton = styled(Skeleton)`
  @media (max-width: 467px) {
    margin-left: auto;
    margin-right: auto;
  }
`




const formatPrice = (inputString) => {
  const match = inputString.match(/^0*\.0*([1-9]\d*|0)\.?(\d*[1-9])?$/);

  if (match) {
    const integerPart = match[1] || '0';
    const decimalPart = match[2] ? match[2].replace(/0+$/, (zeros) => zeros.replace(/0/g, '₀')) : '';

    const leadingZerosCount = match[0].split('.')[1].replace(/[^0]/g, '').length; // Count the number of zeros after the decimal point
    const subscriptCountStr = Array.from(String(leadingZerosCount), digit => String.fromCharCode(8320 + parseInt(digit))).join('');

    return `0.0${subscriptCountStr}${integerPart}${decimalPart}`;
  }

  return inputString;
};



// ==================================================


// const formattedPriceBnb =
//     bnbPriceUsd && bnbPriceUsd
//       ? (Number(bnbPriceUsd.toFixed(15)))
//       : undefined;

// const marketCapMochiPrice =
//     mochiPriceBnb && bnbPriceUsd
//       ? (
//         Number(mochiPriceBnb.toFixed(20)) * Number(bnbPriceUsd.toFixed(20))
//       )
//       .toFixed(20)
//       : undefined;

// const marketCapMochiFinal =
//     marketCapMochiPrice
//       ? (
//         Number(marketCapMochiPrice) * (250000000000000)
//       )
//       .toFixed(1)
//       : undefined;


// ==================================================






const emissionsPerBlock = 9.9

/**
 * User (Planet Finance) built a contract on top of our original manual CAKE pool,
 * but the contract was written in such a way that when we performed the migration from Masterchef v1 to v2, the tokens were stuck.
 * These stuck tokens are forever gone (see their medium post) and can be considered out of circulation."
 * https://planetfinanceio.medium.com/pancakeswap-works-with-planet-to-help-cake-holders-f0d253b435af
 * https://twitter.com/PancakeSwap/status/1523913527626702849
 * https://bscscan.com/tx/0xd5ffea4d9925d2f79249a4ce05efd4459ed179152ea5072a2df73cd4b9e88ba7
 */
const planetFinanceBurnedTokensWei = BigNumber.from('637407922445268000000000')
const cakeVaultAddress = getCakeVaultAddress()

const CakeDataRow = () => {
  const { t } = useTranslation()
  const { observerRef, isIntersecting } = useIntersectionObserver()
  const [loadData, setLoadData] = useState(false)
  const {
    data: { cakeSupply, burnedBalance, circulatingSupply } = {
      cakeSupply: 0,
      burnedBalance: 0,
      circulatingSupply: 0,
    },
  } = useSWR(
    loadData ? ['cakeDataRow'] : null,
    async () => {
      const totalSupplyCall = {
        abi: mochiAbi,
        address: bscTokens.mochi.address,
        name: 'totalSupply'
      }
      const burnedTokenCall = {
        abi: mochiAbi,
        address: bscTokens.mochi.address,
        name: 'balanceOf',
        params: ['0x000000000000000000000000000000000000dEaD'],
      }
      const cakeVaultCall = {
        abi: cakeVaultV2Abi,
        address: cakeVaultAddress,
        name: 'totalLockedAmount',
      }

      const [[totalSupply], [burned], [totalLockedAmount]] = await multicallv3({
        calls: [totalSupplyCall, burnedTokenCall, cakeVaultCall],
        allowFailure: true,
      })
      // const totalBurned = planetFinanceBurnedTokensWei.add(burned)
      const totalBurned = burned;
      const circulating = totalSupply.sub(totalBurned)

      return {
        cakeSupply: totalSupply ? +formatBigNumberMochi(totalSupply) : 0,
        burnedBalance: burned ? +formatBigNumberMochi(totalBurned) : 0,
        circulatingSupply: circulating ? +formatBigNumberMochi(circulating) : 0,
      }
    },
    {
      refreshInterval: SLOW_INTERVAL,
    },
  )

  const circulatingSupplyFormatted = abbreviateTheNumber(circulatingSupply);
  const burnedBalanceFormatted = abbreviateTheNumber(burnedBalance);
  const burnedBalancePercentage = (100 * (burnedBalance / cakeSupply)).toFixed(2)
  const bnbPriceUsd = useBNBBusdPrice({ forceMainnet: true })
  const mochiPriceBnb = useMochiBNBPrice({ forceMainnet: true })

  const formattedPriceMochi =
    mochiPriceBnb && bnbPriceUsd
      ? (
        Number(mochiPriceBnb.toFixed(20)) * Number(bnbPriceUsd.toFixed(20))
      )
      .toFixed(15)
      : undefined;

  const mochiMarketcap = (circulatingSupply * Number((Number(formattedPriceMochi) * 1.002))).toFixed(2);
  const mochiMarketcapFormatted = formatCurrency(Number(mochiMarketcap));
    

  // console.log('aax8 formattedPriceMochi: ', formattedPriceMochi);

  const cakePriceBusd = usePriceCakeBusd()
  const mcap = cakePriceBusd.times(circulatingSupply)
  const mcapString = formatLocalisedCompactNumber(mcap.toNumber())

  useEffect(() => {
    if (isIntersecting) {
      setLoadData(true)
    }
  }, [isIntersecting])

  return (
    <MyStyledFlexBox>
      <StyledColumn noDesktopBorder style={{ gridArea: 'd' }}>
        <Text color="textSubtle">{t('Market cap')}</Text>
        {mcap?.gt(0) && mcapString ? (
          <Heading scale="lg">
            {/* {`$${(function() {
              if (Number(marketCapMochiFinal) >= 1000) {
                return (Number(marketCapMochiFinal) / 1000).toFixed(2) + 'M';
              } else if (Number(marketCapMochiFinal) < 1000) {
                return marketCapMochiFinal + 'K';
              } else {
                return marketCapMochiFinal;
              }
            })()}`} */}
            {mochiMarketcapFormatted}
            <MyStyledSubText color="textSubtle">{t('Price x circulating supply')}</MyStyledSubText>
          </Heading>
        ) : (
          <MyStyledSkeleton height={24} width={126} my="4px" />
        )}
      </StyledColumn>
      <MyStyledDivLine />
      <Flex flexDirection="column" style={{ gridArea: 'a' }}>
        <Text color="textSubtle">{t('Total supply')}</Text>
        {circulatingSupply ? (
          <Heading scale="lg">
            {t('250.00Q')}
            <MyStyledSubText color="textSubtle">{t('No further mint possible!')}</MyStyledSubText>
          </Heading>
        ) : (
          <>
            <div ref={observerRef} />
            <MyStyledSkeleton height={24} width={126} my="4px" />
          </>
        )}
      </Flex>
      <MyStyledDivLine />
      <StyledColumn noMobileBorder style={{ gridArea: 'b' }}>
        <Text color="textSubtle">{t('Circulating Supply')}</Text>
        {circulatingSupply ? (
          <Heading scale="lg">
            {circulatingSupplyFormatted}
            <MyStyledSubText color="textSubtle">{t('Across DEX & CEX')}</MyStyledSubText>
          </Heading>
        ) : (
          <MyStyledSkeleton height={24} width={126} my="4px" />
        )}
      </StyledColumn>
      <MyStyledDivLine />
      <StyledColumn noMobileBorder style={{ gridArea: 'c' }}>
        <Text color="textSubtle">🔥 Burnt Supply</Text>
        {burnedBalance ? (
          <Heading scale="lg">
            {burnedBalanceFormatted}
            <MyStyledSubText color="textSubtle">{burnedBalancePercentage}% toasted forever!</MyStyledSubText>
          </Heading>
        ) : (
          <>
            <div ref={observerRef} />
            <MyStyledSkeleton height={24} width={126} my="4px" />
          </>
        )}
      </StyledColumn>
      {/* <StyledColumn style={{ gridArea: 'e' }}>
        <Text color="textSubtle">{t('Burned to date')}</Text>
        {burnedBalance ? (
          <Balance decimals={0} lineHeight="1.1" fontSize="24px" bold value={burnedBalance} />
        ) : (
          <Skeleton height={24} width={126} my="4px" />
        )}
      </StyledColumn>
      <StyledColumn style={{ gridArea: 'f' }}>
        <Text color="textSubtle">{t('Current emissions')}</Text>

        <Heading scale="lg">{t('%cakeEmissions%/block', { cakeEmissions: emissionsPerBlock })}</Heading>
      </StyledColumn> */}
    </MyStyledFlexBox>
  )
}

export default CakeDataRow
