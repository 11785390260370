import React from "react";
import styled from "styled-components";
import LogoRound from "../Svg/Icons/LogoRound";
import Text from "../Text/Text";
import Skeleton from "../Skeleton/Skeleton";
import { Colors } from "../../theme";

export interface Props {
  color?: keyof Colors;
  cakePriceUsd?: number;
  bnbPriceUsd?: number;
  luigiPriceBnb?: number;
  mochiPriceBnb?: number;
  pepePriceBnb?: number;
  solanaPriceBnb?: number;
  meggPriceBnb?: number;
  mochiMarketCap?: string;
  showSkeleton?: boolean;
  showMegg?: boolean;
}

const PriceLink = styled.a`
  display: flex;
  // display: none !important;
  align-items: center;

  // background-color: #1d2b2d;
  padding: 6px 8px;
  padding-right: 16px;
  padding-right: 4px;
  padding-left: 0 !important;
  border-radius: 16px;
  border-radius: 999px;
  height: 40px;


  // border-bottom-right-radius: 0;
  // border-bottom-left-radius: 0;
  // border-bottom: 1px solid #04e081;

  // :before {
  //   content: '';
  //   display: flex;
  //   background-color: red;
  //   height: 1px;
  //   width: 2px;
  //   position: absolute;
  //   bottom: 0;
  //   left:0;
  //   width: 100%;
  //   background: linear-gradient(180deg, #C88400 0%, #DBA318 44.79%, #F7CF3A 65.62%, #FFDC44 100%);
  //   background: linear-gradient(240deg, #097E31 0%, #01973F 44.79%, #0ED156 65.62%, #00FF9B 100%);
  //   background: rgba(0,0,0,0);
  //   border: 1px solid red;
  //   width: 32px;
  //   height: 32px;
  //   border-radius: 50%;
  // }

  img {
    opacity: 1 !important;
    margin-right: 6px !important;
    width: 24px;
  }

  div {
    font-size: 16px !important;
    opacity: 1 !important;
    line-height: 1 !important;
    font-weight: 500;
  }


  @media (max-width: 1067px) {
    // background-color: red;
    height: 60px;
    margin: 0 !important;
    flex-direction: row;
    img {
      width: 24px !important;
      margin: 0 !important;
      // margin-bottom: 2px !important;
      margin-right: 4px !important;
    }
    div {
      font-size: 12px !important;
      font-size: 14px !important;
    }

  }

  @media (max-width: 575px) {
    img {
      width: 20px !important;
      border: 1px solid #2f0014;
      border-radius: 999px;
    }
    div {
      font-size: 10px !important;
      font-size: 12px !important;
      font-size: 13px !important;
    }
  }

  @media (max-width: 467px) {
    img {
      display: none;
    }
  }


  svg {
    transition: transform 0.3s;
  }
  :hover {
    svg {
      transform: scale(1.2);
    }
  }




`;

const CakePrice: React.FC<React.PropsWithChildren<Props>> = ({
  cakePriceUsd,
  bnbPriceUsd,
  luigiPriceBnb,
  mochiPriceBnb,
  mochiMarketCap,
  pepePriceBnb,
  solanaPriceBnb,
  meggPriceBnb,
  color = "textSubtle",
  showSkeleton = true,
  showMegg = true,
}) => {
  // console.log('luigiPriceBnb:', luigiPriceBnb);
  // console.log('bnbPriceUsd:', bnbPriceUsd);
  // return cakePriceUsd ? (

  const formatPrice = (inputString: string) => {
    const match = inputString.match(/^0*\.0*([1-9]\d*|0)\.?(\d*[1-9])?$/);
  
    if (match) {
      const integerPart = match[1] || '0';
      const decimalPart = match[2] ? match[2].replace(/0+$/, (zeros) => zeros.replace(/0/g, '₀')) : '';
  
      const leadingZerosCount = match[0].split('.')[1].replace(/[^0]/g, '').length; // Count the number of zeros after the decimal point
      const subscriptCountStr = Array.from(String(leadingZerosCount), digit => String.fromCharCode(8320 + parseInt(digit))).join('');
  
      return `0.0${subscriptCountStr}${integerPart}${decimalPart}`;
    }
  
    return inputString;
  };



  // Test
  const formattedPriceLuigiTest =
    luigiPriceBnb
      ? 
        Number(luigiPriceBnb.toFixed(30))
      : undefined;

  // console.log('PPP2: formattedPriceLuigiTest: ' + formattedPriceLuigiTest)
  
  // Test
  const formattedPriceMochiTest =
    mochiPriceBnb
      ? 
        Number(mochiPriceBnb.toFixed(30))
      : undefined;

  // console.log('PPP2: formattedPriceMochiTest: ' + formattedPriceMochiTest)
  

  // Good

  const formattedPriceLuigi =
    luigiPriceBnb && bnbPriceUsd
      ? (
        Number(luigiPriceBnb.toFixed(20)) * Number(bnbPriceUsd.toFixed(20))
      )
      .toFixed(15)
      : undefined;

      // console.log('PPP99: formattedPriceLuigi: ' + formattedPriceLuigi)

  const formattedPriceMochi =
    mochiPriceBnb && bnbPriceUsd
      ? (
        Number(mochiPriceBnb.toFixed(20)) * Number(bnbPriceUsd.toFixed(20))
      )
      .toFixed(15)
      : undefined;

  const formattedPricePepe =
    pepePriceBnb && bnbPriceUsd
      ? (
        Number(pepePriceBnb.toFixed(20)) * Number(bnbPriceUsd.toFixed(20))
      )
      .toFixed(15)
      : undefined;

  const formattedPriceSolana =
    solanaPriceBnb && bnbPriceUsd
      ? (
        Number(solanaPriceBnb.toFixed(20)) * Number(bnbPriceUsd.toFixed(20))
      )
      .toFixed(15)
      : undefined;


  // console.log('PPP101: formattedPriceMochi: ' + formattedPriceMochi)

  const formattedPriceMegg =
    meggPriceBnb && bnbPriceUsd
      ? (
        Number(meggPriceBnb.toFixed(20)) * Number(bnbPriceUsd.toFixed(20))
      )
      .toFixed(15)
      : undefined;

  const marketCapLuigiPrice =
    luigiPriceBnb && bnbPriceUsd
      ? (
        Number(luigiPriceBnb.toFixed(20)) * Number(bnbPriceUsd.toFixed(20))
      )
      .toFixed(20)
      : undefined;

  // console.log('XXXX3 Luigi: ' + marketCapLuigiPrice)

  const marketCapMochiPrice =
    mochiPriceBnb && bnbPriceUsd
      ? (
        Number(mochiPriceBnb.toFixed(20)) * Number(bnbPriceUsd.toFixed(20))
      )
      .toFixed(20)
      : undefined;

  // console.log('XXXX3 Mochi: ' + marketCapMochiPrice)

  const marketCapMeggPrice =
   meggPriceBnb && bnbPriceUsd
      ? (
        Number(meggPriceBnb.toFixed(20)) * Number(bnbPriceUsd.toFixed(20))
      )
      .toFixed(20)
      : undefined;

  const marketCapLuigiFinal =
    marketCapLuigiPrice
      ? (
        Number(marketCapLuigiPrice) * (42000000000000)
      )
      .toFixed(1)
      // .replace('.', ',')
      : undefined;

  // const marketCapMochiFinal = '∞';

  const marketCapMochiFinal =
    marketCapMochiPrice
      ? (
        Number(marketCapMochiPrice) * (250000000000000)
      )
      .toFixed(1)
      // .replace('.', ',')
      : undefined;

  // console.log('XmarketCapMochiFinal: ' + marketCapMochiFinal);

  const marketCapMeggFinal =
    marketCapMeggPrice
      ? (
        Number(marketCapMeggPrice) * (21000000000000)
      )
      .toFixed(1)
      // .replace('.', ',')
      : undefined;

  // console.log('MARKETCAP LUIGI FINAL: ' + marketCapLuigiFinal)
  // console.log('formattedPrice type: ' + typeof formattedPriceLuigi)

  const finalFormattedPriceLuigi =
  formattedPriceLuigi
      ? formatPrice(formattedPriceLuigi)
      : undefined;

  const finalFormattedPriceMochi =
  formattedPriceMochi
      ? formatPrice(formattedPriceMochi)
      : undefined;

  // console.log('finalFormattedPriceMochi: ' + finalFormattedPriceMochi);

  // console.log('AOAOAO: ' + finalFormattedPriceMochi);
  
  const finalFormattedPriceMegg =
  formattedPriceMegg
      ? formatPrice(formattedPriceMegg)
      : undefined;

  // console.log('xx99 mochiMarketCap: ',mochiMarketCap);
  // console.log('xx99 typeof: ',typeof mochiMarketCap);

  const mochiMarketCapString = mochiMarketCap.toString();

  // if (mochiMarketCapString === '$NaNundefined') {
  //   mochiMarketCapStringFinal = '0';
  // } else if (mochiMarketCapString === '0') {
  //   console.log('xx99 status: NO');
  // } else {
  //   console.log('xx99 status: YES!!!');
  // }

  return finalFormattedPriceLuigi && !showMegg ? (
    <PriceLink
      style={{borderColor: '#04e081'}}
      // href="https://pancakeswap.finance/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82&chainId=56"
      href="/swap?outputCurrency=0x92072F045D0904e9a0cDfD48519f54c83Bf41e82&chain=bsc"
      // target="_blank"
      target="_self"
    >
      {/* <LogoRound width="24px" mr="8px" /> */}
      <img src="https://mochidefi.io/static/media/mochiOrigBig.45505d820d3393736955.png" alt="Mochi DeFi Token" width="24px" style={{ marginRight: '8px' }} />
      {/* <Text color={color} bold>{`$${cakePriceUsd.toFixed(2)}`}</Text> */}
      {/* <Text color={color} bold>{`$${
        // finalFormattedPriceLuigi
        marketCapMochiFinal
        }`}K
      </Text> */}
      {/* <Text color={color} bold> */}
        {/* {`$${(function() {
          if (Number(marketCapMochiFinal) >= 1000) {
            return (Number(marketCapMochiFinal) / 1000).toFixed(2) + 'M';
          } else if (Number(marketCapMochiFinal) < 1000) {
            return marketCapMochiFinal + 'K';
          } else {
            return marketCapMochiFinal;
          }
        })()}`} */}
        {/* {mochiMarketCap} */}
        {(mochiMarketCapString === '0' || mochiMarketCapString === '$NaNundefined') ? (
          <Skeleton height={24} width={54} my="4px" />
        ) : (
          <Text color={color} bold>{mochiMarketCap}</Text>
        )}
      {/* </Text> */}
    </PriceLink>
  ) : finalFormattedPriceMegg && showMegg ? (
    <PriceLink
      style={{borderColor: '#ffc900'}}
      // href="https://pancakeswap.finance/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82&chainId=56"
      href="/swap?outputCurrency=0x8258c4b51a73fC114d5ACF04F429E25695fd5488&chain=bsc"
      // target="_blank"
      target="_self"
    >
      {/* <LogoRound width="24px" mr="8px" /> */}
      <img src="https://magicegg.vip/megg-token-64.png" alt="Magic Egg Token" width="24px" style={{ marginRight: '8px' }} />
      {/* <Text color={color} bold>{`$${cakePriceUsd.toFixed(2)}`}</Text> */}
      <Text color={color} bold>{`$${
        // finalFormattedPriceMegg
        marketCapMeggFinal
        }`}K
      </Text>
    </PriceLink>
  )
  : showSkeleton ? (
    <Skeleton width={80} height={24} />
  ) : null;
};

export default React.memo(CakePrice);
